.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout main {
  margin-top: 3rem;
  flex-grow: 1;
}

.App {
  text-align: center;
}

.theme {
  margin: 0 0.5rem;
}

.topDiv {
  margin-top: 4rem;
  
}

.navbar-brand{
  align-items: center;
}

.menuItem{  
  position: absolute;
  right: 0;
}

.menuBtn{
  border: 0;
}

.btmDiv {
  bottom: 0;
  width: 100%;
}


.progress:indeterminate {
  animation-duration: 4s;
}

.progressDiv {
  margin: 0 4rem;
}


.upper {
  margin: 5rem 0 0 0;
}

.plan-box {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
  color: #4a4a4a;
  display: block;
  padding-bottom: 0.4rem;
}

.plan-item {
    background-color: #f5f5f5;
    border-radius: 4px;
    border-bottom: 0.15rem solid #ffffff;
    position: relative;
    padding: 0.25rem 1.5rem 0.25rem 0.5rem;
    height: 2.5rem;
}


.active-plan-box {
  background-color: #fff;
  border-radius: 7px;
  /* box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02); */
  color: #4a4a4a;
  display: block;
  padding-bottom: 0.4rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(-9px);

}


/* https://cssgradient.io/ */
.chero {
  background: radial-gradient(circle, rgba(138,178,255,1) 3%, rgba(56,80,183,1) 26%, rgba(8,29,121,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.maxtitle {
  font-size: 5rem;
  background: radial-gradient(circle, rgba(138,178,255,1) 3%, rgba(56,80,183,1) 26%, rgba(8,29,121,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}



.formContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 100%;
}

.formIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.boxborder {
  margin: .8rem 0;
  border-bottom: solid #485fc7;
}


.desk-mb {
  margin-bottom: 1rem;

}

.navbar-logo {
  width: 60%;
  height: 60%;
}

/* If screen size is more than 850px wide = desktop view */
@media screen and (min-width: 850px){
  .desk-m {
    margin-bottom: 2rem;

  }
  .thin-col {
    max-width: 3rem;
  }
  .upper {
    margin: 5rem 0 0 0;
  }
  
  .footer {
    background-color: #e0e3f3;
  }

  .footertheme {
    margin: 0 8rem;
    
  }

  .theme {
    margin: 0 10rem;
  }

  .menuItem{
    margin-right: 9.2rem;
   }

  .payments {
    width: 30%;
    height: 30%
  }

  .progressDiv {
    margin: 0 20rem;
  }

  /* .signlinksContainer {
    margin: 0 1rem; 
  } */

  .signLink {
    vertical-align: top;
  }

  .shortenField {
    max-width: 40%;
  }

  .btmDiv {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .btmDivResults {
    margin-bottom: auto;
    bottom: 0;
    width: 100%;
  }

  }



.testt {
  background-color:blue;
  z-index:2
}
 


/* If screen size is less than 500px wide = nobile view */
@media screen and (min-width: 500px) {

}
